<template>
  <MDBContainer>


    <MDBRow class="align-items-start">
      <MDBCol>
        <MDBCard class="mb-4">
          <img
            src="../assets/images/dashboard/data-dex.png"
            style="height: 200px"
            alt="Data Decentralization"
          />
          <MDBCardBody>
            <MDBCardTitle>Data Decentralization</MDBCardTitle>
            <MDBCardText>
              தமிழ் தகவல் பரவலாக்கம் <br />
              Protecting and preserving Tamil digital assets.
            </MDBCardText>
            <MDBBtn tag="a" href="https://app.tamiltoken.org" color="danger"
              ><i class="fas fa-hands-helping" aria-hidden="true"></i> Join
              Now</MDBBtn
            >
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

      <MDBCol>
        <MDBCard class="mb-4">
          <img
            src="../assets/images/dashboard/home-trx.png"
            style="height: 200px"
            alt="Data Decentralization"
          />
          <MDBCardBody>
            <MDBCardTitle>Rewarding Tamil Language Projects</MDBCardTitle>
            <MDBCardText>
              தமிழ் மொழி வளர்ச்சி செயல் திட்டங்களுக்கு ஆதரவு  <br />
              Get Rewarded in Tamil Crypto for Organizing a Tamil Language Enrichment Activity
            </MDBCardText>
            <MDBBtn tag="a" href="https://app.tamiltoken.org" color="danger"
              ><i class="fas fa-hands-helping" aria-hidden="true"></i> Join
              Now</MDBBtn
            >
          </MDBCardBody>
        </MDBCard>
      </MDBCol>

    </MDBRow>
  </MDBContainer>
</template>

<script>
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";

export default {
  components: {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBContainer,
    MDBBtn,
  },
};
</script>

<style scoped>
.btn-danger {
  color: #fff;
  background-color: #d50000;
}

.card2 {
  min-height: 460px;
}
</style>
